import React from 'react';

import PromotionCards from '../components/cards/PromotionCards';
import UserHeader from '../components/utility/navigation/UserHeader';

const PromotePage = () => {
  return (
    <div data-testid="promote-page" className="page-content">
      <UserHeader title="PAGE-TITLES.PROMOTE" />
      <div className="mt20">
        <PromotionCards />
      </div>
    </div>
  );
};

export default PromotePage;
